import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  const featuredImage = {
    data: posts.featuredImage?.node?.guid,
    alt: posts.featuredImage?.node?.altText || ``,
  }

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />

      {/* <Bio /> */}
      <div className="flex flex-wrap ">
        {posts.map(function(post, i) {
            const title = post.title
            const postImage = posts[i].featuredImage?.node?.guid
            
            return (
              <div className="flex p-2 w-1/2">
                <div className="mr-4 mb-2 flex-shrink-0">
                  
                  {postImage
                    ? <img
                        src={postImage}
                        alt={featuredImage.alt}
                        className="h-full w-16 border border-gray-300 bg-white text-gray-300"
                      />
                    : 
                    
                      <svg
                        className="h-full w-16 border border-gray-300 bg-white text-gray-300"
                        preserveAspectRatio="none"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 200 200"
                        aria-hidden="true"
                      >
                        <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                      </svg>
                  
                  }
                </div>
                <div>
                  <h4 className="text-sm font-bold">
                    <Link to={post.uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                  </h4>
                  <small>{post.date}</small>
                  <p className="mt-1 text-xs">
                    {parse(post.excerpt)}
                  </p>
                </div>
              </div>
              )
        })}
      </div>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            guid
          }
        }
      }
    }
  }
`
